export default {
    name: 'Виктория',
    position: 'Менеджер отдела продаж',
    personImg: './img/content/person.png',
    dealer: 'БИЗНЕС КАР Лосиный Остров',
    brand: 'Geely',
    address: 'г. Москва, 95-й км МКАД,  <br>внешняя сторона',
    ogranization: 'ООО «Оптимум Трейдинг» – официальный дилер Geely в Москве. <br>Юр.адрес: Москва, Балаклавский пр-т, 26 <br>ИНН 7727836954 ОГРН 1147746690090',
    phone: '+7 (499) 444-62-95',
    banner: './img/content/intro/intro',
    title: 'Марафон выгодных предложений по Geely!',
    subtitle: 'Максимальная выгода <br>до 640 000 ₽ + подарки + сниженная кредитная ставка <br>Хотите больше? <br>Лучший шанс получить личную максимальную выгоду по телефону! <br>Только до 15 мая',
    mainSite: 'https://sale.geely-bc.ru/',
    policyTextLink: 'https://service.geely-ot.ru/soglasye-dlya-clientov.html',
}