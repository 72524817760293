<template>
  <header class="header">
    <div class="header__container container">
      <a href="/" :class="`header__logo`">
        <picture>
          <source media="(max-width: 768px)" :srcset="`./img/content/logo_xs.svg`">
          <img :src="`img/content/logo.svg`">
        </picture>
        <span class="header__dealer">
          <span class="header__dealer-name">{{ dealer.dealer }}</span> <br>ОФИЦИАЛЬНЫЙ ДИЛЕР
        </span>
      </a>
      <div class="header__right-block">
        <span class="header__address" v-html="`${dealer.address}<br>`"></span>
        <a class="header__phone" :href="`tel:+${dealer.phone.replace(/[^0-9]/g, '')}`">{{ dealer.phone }}</a>
      </div>
    </div>
  </header>
  <router-view></router-view>
</template>

<script>
// Данные ДЦ
import dealer from '@/data//dealer.js'

export default {
  name: 'App',

  data() {
    return {
      dealer: dealer,
    }
  },
}
</script>

<style></style>
